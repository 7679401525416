import { useAuth0 } from '@auth0/auth0-react';
import { useState, Children, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Input } from 'components/common/Input';
import { RemovableItemField } from 'components/common/RemovableItemField';
import { ImageInput } from 'components/sendRecipe/ImageInput';
import { IngredientInput } from 'components/sendRecipe/IngredientInput';
import { InstructionInput } from 'components/sendRecipe/InstructionInput';
import { CreateRecipeRequest } from 'models/recipes';
import { ROUTE_PATHS } from 'models/routes';

import { useRecipesApi } from 'api/useRecipesApi';

export const SendRecipe = () => {
  const { createRecipe } = useRecipesApi();
  const { t } = useTranslation();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [ingredients, setIngredients] = useState<string[]>([]);
  const [instructions, setInstructions] = useState<string[]>([]);
  const [image, setImage] = useState<string>();
  const [source, setSource] = useState<string>('');

  const handleSubmit = async () => {
    if (name.length < 3) return toast.warning(`${t('pages.send-recipe.alert.name-too-short')}`);
    if (!image) return toast.warning(`${t('pages.send-recipe.alert.image-missing')}`);
    if (ingredients.length < 3)
      return toast.warning(`${t('pages.send-recipe.alert.not-enough-ingredients')}`);
    if (instructions.length < 3)
      return toast.warning(`${t('pages.send-recipe.alert.not-enough-instructions')}`);
    try {
      await createRecipe({
        name,
        image,
        ingredients,
        instructions,
        source,
      });
      toast.success(`${t('pages.send-recipe.alert.submitted-successfully')}`);
      return navigate(ROUTE_PATHS.recipes);
    } catch {
      return toast.error(`${t('alert.unknown-error')}`);
    }
  };

  const handleUnauthenticatedSubmit = async () => {
    saveValuesToSessionStorage();
    await loginWithRedirect({
      appState: { returnTo: window.location.pathname },
    });
  };

  const saveValuesToSessionStorage = () => {
    sessionStorage.setItem(
      'saved_values',
      JSON.stringify({
        name,
        image,
        ingredients,
        instructions,
        source,
      })
    );
  };

  useEffect(() => {
    const savedValues = sessionStorage.getItem('saved_values');
    if (!!savedValues) {
      const values = JSON.parse(savedValues) as CreateRecipeRequest;
      setName(values.name);
      setImage(values.image);
      setIngredients(values.ingredients);
      setInstructions(values.instructions);
      if (values.source) setSource(values.source);
      sessionStorage.removeItem('saved_values');
    }
  }, []);

  return (
    <div className="lg:w-2/3 mx-auto p-5 space-y-5">
      <h1 className="text-center text-3xl font-bold">{t('pages.send-recipe.main-title')}</h1>
      <div className="text-center text-lg font-semibold mt-1">
        {t('pages.send-recipe.subtitle')}
      </div>
      <div className="text-center">
        <Input
          wrapperClasses="mx-auto text-center"
          inputClasses="text-center text-2xl"
          value={name}
          onValueChange={(value: string) => setName(value)}
          placeholder={t('pages.send-recipe.name.placeholder')}
        />
      </div>
      <div className="flex flex-col md:flex-row space-y-5">
        <ImageInput defaultValue={image} onImageSelect={setImage} />
        <div className="flex flex-col w-full mt-3 mx-auto md:w-1/2 md:pl-4">
          <h6 className="font-semibold text-lg">{t('pages.send-recipe.ingredients.label')}</h6>
          <div className="flex flex-col text-lg h-full pl-4 divide-y">
            {Children.toArray(
              ingredients.map((i) => (
                <RemovableItemField
                  display={i}
                  onRemove={() => setIngredients((prevState) => prevState.filter((e) => e !== i))}
                />
              ))
            )}
            <IngredientInput
              onSubmit={(ingredient: string) =>
                setIngredients((prevState) => [...prevState, ingredient])
              }
            />
          </div>
        </div>
      </div>
      <div className="md:pl-5 mt-5">
        <h5 className="font-semibold text-lg">{t('pages.send-recipe.instructions.label')}</h5>
        <div className="flex flex-col md:ml-6 pl-4 divide-y">
          {Children.toArray(
            instructions.map((instruction, index) => (
              <RemovableItemField
                display={
                  <div className="flex gap-2">
                    <div>{index + 1}.</div>
                    <div>{instruction}</div>
                  </div>
                }
                onRemove={() =>
                  setInstructions((prevState) => prevState.filter((i) => i !== instruction))
                }
              />
            ))
          )}
          <InstructionInput
            onSubmit={(val: string) => setInstructions((prevState) => [...prevState, val])}
          />
        </div>
        <div className="mt-12">
          <input
            className="border-b-2 border-gray-300 placeholder-gray-400 px-2 placeholder-black text-sm w-64"
            placeholder={t('pages.send-recipe.source.placeholder')}
            type="text"
            value={source}
            onChange={(e) => setSource(e.target.value)}
          />
        </div>
        <div className="max-w-xs text-center mr-0 ml-auto">
          <button
            type="button"
            className="primary-button-lg mt-10"
            onClick={isAuthenticated ? () => handleSubmit() : () => handleUnauthenticatedSubmit()}
          >
            {isAuthenticated ? t('pages.send-recipe.submit-recipe') : t('login')}
          </button>
          {!isAuthenticated && (
            <div className="text-gray-400 text-sm">{t('pages.send-recipe.submit-recipe-info')}</div>
          )}
        </div>
      </div>
    </div>
  );
};

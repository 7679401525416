import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';

interface Api {
  sendGet: <ResultType>(url: string, options?: RequestInit) => Promise<ResultType>;
  sendPost: <RequestType, ResultType>(
    url: string,
    body: RequestType,
    options?: RequestInit
  ) => Promise<ResultType>;
  sendDelete: <RequestType, ResultType>(
    url: string,
    body: RequestType,
    options?: RequestInit
  ) => Promise<ResultType>;
}

const apiUrl = process.env.REACT_APP_API_SERVER_URL || '';

export const useApi = (): Api => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const accessToken = isAuthenticated ? getAccessTokenSilently() : '';

  const sendGet = useCallback(
    async <ResultType>(url: string, options?: RequestInit) => {
      const headers = new Headers(options?.headers);
      if (isAuthenticated) {
        headers.set('Authorization', `Bearer ${await accessToken}`);
      }
      const response = await fetch(`${apiUrl}${url}`, {
        ...options,
        method: 'GET',
        headers,
      });

      if (!response.ok) {
        throw new Error(`GET ${url} failed with status ${response.status}`);
      }

      return (await response.json().catch(() => null)) as ResultType;
    },
    [accessToken, isAuthenticated]
  );

  const sendPost = useCallback(
    async <RequestType, ResultType>(url: string, body: RequestType, options?: RequestInit) => {
      const response = await fetch(`${apiUrl}${url}`, {
        ...options,
        method: 'POST',
        headers: {
          ...options?.headers,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await accessToken}`,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`POST ${url} failed with status ${response.status}`);
      }

      return (await response.json().catch(() => null)) as ResultType;
    },
    [accessToken]
  );

  const sendDelete = useCallback(
    async <RequestType, ResultType>(url: string, body: RequestType, options?: RequestInit) => {
      const response = await fetch(`${apiUrl}${url}`, {
        ...options,
        method: 'DELETE',
        headers: {
          ...options?.headers,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await accessToken}`,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`DELETE ${url} failed with status ${response.status}`);
      }

      return (await response.json().catch(() => null)) as ResultType;
    },
    [accessToken]
  );

  return { sendGet, sendPost, sendDelete };
};

import { FC } from 'react';

import { Footer } from 'components/layout/Footer';
import { Header } from 'components/layout/header/Header';

interface PageWrapperProps {
  children: JSX.Element | JSX.Element[];
}

export const Layout: FC<PageWrapperProps> = ({ children }) => (
  <div className="min-h-screen relative pb-[100px]">
    <Header />
    {children}
    <Footer />
  </div>
);

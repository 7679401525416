import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageLoader } from 'components/ui/PageLoader';
import { ROUTE_PATHS } from 'models/routes';
import { AuthUser, USER_ROLES } from 'models/user';

interface AdminGuardProps {
  children: ReactNode;
}

export const AdminGuard = ({ children }: AdminGuardProps) => {
  const { user, isLoading } = useAuth0<AuthUser>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.user_roles.includes(USER_ROLES.admin)) navigate(ROUTE_PATHS.recipes);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading ? <PageLoader /> : <>{children}</>;
};

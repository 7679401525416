import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface InstructionInputProps {
  onSubmit: (val: string) => void;
}
export const InstructionInput: FC<InstructionInputProps> = ({ onSubmit }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState('');
  const [isEditActive, setIsEditActive] = useState(false);

  const onClose = useCallback(() => {
    setValue('');
    setIsEditActive(false);
  }, []);

  const handleSubmit = useCallback(() => {
    if (value.length === 0) return toast.warning(`${t('alert.input-empty')}`, { theme: 'colored' });

    onSubmit(value);
    onClose();
  }, [onClose, onSubmit, t, value]);

  return (
    <>
      {isEditActive ? (
        <div className="flex gap-2 pt-2 pb-0.5">
          <textarea
            className="border-2 border-gray-300 pl-2 rounded-lg placeholder-gray-600 resize-none w-full"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleSubmit();
            }}
          />
          <div className="flex justify-end gap-3">
            <button
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <span className="icon-check text-green-600 border-2 border-green-600 rounded-full block p-1 font-semibold" />
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
            >
              <span className="icon-close text-red-600 border-2 border-red-600 rounded-full block p-1 font-semibold" />
            </button>
          </div>
        </div>
      ) : (
        <div className="pt-2">
          <button
            onClick={(e) => {
              e.preventDefault();
              setIsEditActive(true);
            }}
          >
            <span className="icon-plus text-sm font-bold text-green-600 border-2 border-green-600 rounded-full block p-1" />
          </button>
        </div>
      )}
    </>
  );
};

import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { AuthGuard } from 'components/auth0/AuthGuard';
import { ROUTE_PATHS } from 'models/routes';
import { MainPage } from 'pages/MainPage';
import { PendingRecipePage } from 'pages/PendingRecipePage';
import { PendingRecipesPage } from 'pages/PendingRecipesPage';
import { PublishedRecipePage } from 'pages/PublishedRecipePage';
import { PublishedRecipesPage } from 'pages/PublishedRecipesPage';
import { SendRecipe } from 'pages/SendRecipe';

import { AdminGuard } from '../components/auth0/AdminGuard';

export const Router = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path={ROUTE_PATHS.mainPage} index element={<MainPage />} />
      <Route path={ROUTE_PATHS.recipes} element={<PublishedRecipesPage />} />
      <Route
        path={ROUTE_PATHS.pendingRecipes}
        element={
          <AuthGuard
            element={
              <AdminGuard>
                <PendingRecipesPage />
              </AdminGuard>
            }
          />
        }
      />
      <Route path={ROUTE_PATHS.sendRecipe} element={<SendRecipe />} />
      <Route path={`${ROUTE_PATHS.recipe}/:recipeId`} element={<PublishedRecipePage />} />
      <Route
        path={`${ROUTE_PATHS.pendingRecipe}/:recipeId`}
        element={
          <AuthGuard
            element={
              <AdminGuard>
                <PendingRecipePage />
              </AdminGuard>
            }
          />
        }
      />
    </Routes>
  );
};

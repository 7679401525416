import { FC } from 'react';

interface InputProps {
  value: string;
  onValueChange: (value: string) => void;
  placeholder?: string;
  wrapperClasses?: string;
  inputClasses?: string;
  onSubmit?: () => void;
  submitButton?: JSX.Element;
}

export const Input: FC<InputProps> = ({
  placeholder,
  value,
  onValueChange,
  wrapperClasses,
  inputClasses,
  onSubmit,
  submitButton,
}) => (
  <div
    className={`bg-white max-w-full w-[fit-content] flex flex-row justify-between px-2 rounded-md border-gray-300 border-2 ${
      wrapperClasses || ''
    }`}
  >
    <input
      type="text"
      placeholder={placeholder}
      autoComplete="off"
      className={`focus:outline-none w-full ${inputClasses || ''}`}
      value={value}
      onChange={(e) => onValueChange(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onSubmit && onSubmit();
        }
      }}
    />
    {submitButton}
  </div>
);

import { useTranslation } from 'react-i18next';

const authorFacebookProfileLink = process.env.REACT_APP_AUTHOR_FACEBOOK_PROFILE_LINK || '';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-green-100 text-sm w-full text-gray-600 py-4 font-semibold text-center absolute bottom-0 h-[80px] flex flex-col items-center justify-center">
      <a href={authorFacebookProfileLink} target="_blank" rel="noreferrer">
        <span className="icon-facebook mr-1" />
        {t('author.name')}
      </a>
      <a href={`tel:${t('author.phone-number')}`}>
        <span className="icon-phone mr-1" />
        {t('author.phone-number')}
      </a>
      <a href={`mailto:${t('author.email')}`}>
        <span className="icon-envelop mr-1" />
        {t('author.email')}
      </a>
    </footer>
  );
};

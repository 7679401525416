import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { Auth0ProviderWithNavigate } from 'components/auth0/Auth0ProviderWithRedirect';

import { App } from 'App';

import './i18n';

const container = document.querySelector('#root');
const root = createRoot(container as Element);

root.render(
  <BrowserRouter>
    <Auth0ProviderWithNavigate>
      <App />
    </Auth0ProviderWithNavigate>
  </BrowserRouter>
);

import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { HeaderLink } from 'components/layout/header/HeaderLink';
import { LoginButton } from 'components/layout/header/LoginButton';
import { MobileMenuButton } from 'components/layout/header/MobileMenuButton';
import { UserDropdown } from 'components/layout/header/UserDropdown';
import logo from 'images/logo.png';
import { ROUTE_PATHS } from 'models/routes';
import { AuthUser, USER_ROLES } from 'models/user';

export const Header = () => {
  const { t } = useTranslation();
  const { isAuthenticated, user } = useAuth0<AuthUser>();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <header className="relative z-10">
      <nav className="bg-green-100 px-4 lg:px-6 py-2.5">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <Link to={ROUTE_PATHS.mainPage} className="flex items-center">
            <img src={logo} className="mx-2 h-6 sm:h-9" alt={`${t('page-title')} logo`} />
            <span className="self-center text-xl font-semibold whitespace-nowrap">
              {t('page-title')}
            </span>
          </Link>
          <div className="flex items-center lg:order-2">
            {isAuthenticated ? <UserDropdown /> : <LoginButton />}
            <MobileMenuButton onClick={() => setIsMobileMenuOpen((prevState) => !prevState)} />
          </div>
          <div
            className={`w-full lg:flex lg:w-auto lg:order-1 duration-300 lg:duration-0 ease-linear overflow-hidden lg:h-full lg:max-h-full ${
              isMobileMenuOpen ? 'h-full max-h-[300px]' : 'h-0 max-h-0'
            }`}
            onClick={() => setIsMobileMenuOpen(false)}
          >
            <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
              <HeaderLink to={ROUTE_PATHS.mainPage} title={t('pages.main-page.title')} />
              <HeaderLink to={ROUTE_PATHS.recipes} title={t('pages.recipes.title')} />
              <HeaderLink to={ROUTE_PATHS.sendRecipe} title={t('pages.send-recipe.title')} />
              {user?.user_roles.includes(USER_ROLES.admin) && (
                <HeaderLink
                  to={ROUTE_PATHS.pendingRecipes}
                  title={t('pages.pending-recipes.title')}
                />
              )}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import heroImage from 'images/hero.png';
import { ROUTE_PATHS } from 'models/routes';

export const Hero = () => {
  const { t } = useTranslation();
  return (
    <section
      className="bg-green-100 px-6 pt-6 pb-12  mb-4"
      style={{ clipPath: 'polygon(0 0, 100% 0, 100% 90%, 0 100%)' }}
    >
      <div className="px-4 py-8 mx-auto lg:py-16 lg:flex lg: gap-4 justify-center items-center lg:w-9/12">
        <div className="mr-auto place-self-center">
          <h1 className="font-display text-center mx-auto lg:mx-0 lg:text-left lg:w-2/3 mb-4 text-4xl tracking-tight md:text-5xl xl:text-6xl">
            <Trans
              i18nKey="pages.main-page.hero.title"
              components={{ highlight: <span className="text-green-600" /> }}
            />
          </h1>
          <div className="mt-6 lg:mt-16 flex gap-4 justify-center lg:justify-start">
            <Link to={ROUTE_PATHS.recipes} className="inline-block primary-button-lg">
              {t('pages.main-page.hero.primary-button')}
            </Link>
            <HashLink
              smooth
              to="/#veganism"
              className="inline-block px-5 text-green-600 py-3 font-medium text-center"
            >
              {t('pages.main-page.hero.secondary-button')}
            </HashLink>
          </div>
        </div>
        <div className="mt-4 mx-auto lg:w-auto lg:mt-0 lg:mt-0 lg:flex">
          <img
            className="mx-auto rounded-lg object-contain w-[fit-content] h-[fit-content] max-w-[300px] mx-h-[200px]"
            alt="mockup"
            src={heroImage}
          />
        </div>
      </div>
    </section>
  );
};

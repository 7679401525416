import { FC } from 'react';

interface RemovableItemField {
  display: string | JSX.Element;
  onRemove: () => void;
}

export const RemovableItemField: FC<RemovableItemField> = ({ display, onRemove }) => (
  <div className="flex justify-between pt-1 pb-0.5">
    <div>{display}</div>
    <button
      onClick={(e) => {
        e.preventDefault();
        onRemove();
      }}
    >
      <span className="icon-delete font-bold text-red-600 border-2 border-red-600 rounded-full block p-1" />
    </button>
  </div>
);

import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface IngredientInput {
  onSubmit: (value: string) => void;
}

export const IngredientInput: FC<IngredientInput> = ({ onSubmit }) => {
  const { t } = useTranslation();

  const [ingredient, setIngredient] = useState('');
  const [isEditActive, setIsEditActive] = useState(false);

  const onClose = useCallback(() => {
    setIsEditActive(false);
    setIngredient('');
  }, []);

  const handleSubmit = useCallback(() => {
    if (ingredient.length === 0)
      return toast.warning(`${t('alert.input-empty')}`, { theme: 'colored' });

    onSubmit(ingredient);
    onClose();
  }, [ingredient, t, onSubmit, onClose]);

  return isEditActive ? (
    <div className="flex gap-2 pt-2 pb-0.5 justify-between">
      <input
        className="border-2 border-gray-300 text-center rounded-lg placeholder-black text-sm w-full"
        type="text"
        value={ingredient}
        onChange={(e) => setIngredient(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') handleSubmit();
        }}
      />
      <div className="flex justify-end gap-3">
        <button
          onClick={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <span className="icon-check text-green-600 border-2 border-green-600 rounded-full block p-1 font-semibold" />
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        >
          <span className="icon-close text-red-600 border-2 border-red-600 rounded-full block p-1 font-semibold" />
        </button>
      </div>
    </div>
  ) : (
    <div className="pt-2">
      <button
        onClick={(e) => {
          e.preventDefault();
          setIsEditActive(true);
        }}
      >
        <span className="icon-plus text-sm font-bold text-green-600 border-2 border-green-600 rounded-full block p-1" />
      </button>
    </div>
  );
};

import { Link, useLocation } from 'react-router-dom';

import { ROUTE_PATHS } from 'models/routes';

interface HeaderLinkProps {
  to: ROUTE_PATHS;
  title: string;
}

export const HeaderLink = ({ to, title }: HeaderLinkProps) => {
  const location = useLocation();
  return (
    <li>
      <Link
        to={to}
        className={`block w-[fit-content] mx-auto font-bold px-3 py-1 center ${
          location.pathname === to ? 'border-b-2 rounded-md border-black' : ''
        }`}
        aria-current="page"
      >
        {title}
      </Link>
    </li>
  );
};

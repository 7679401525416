import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  const { t } = useTranslation();

  return (
    <button
      type="button"
      className="w-[fit-content] bg-green-500 hover:bg-green-600 text-white font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2"
      onClick={() => loginWithRedirect()}
    >
      {t('login')}
    </button>
  );
};

interface CardProps {
  title: string;
  body: string;
}

export const Card = ({ title, body }: CardProps) => (
  <div className="w-[350px] h-full text-center bg-green-200 rounded-lg p-4 border-2 border-green-700 mx-auto md:mx-0">
    <h6 className="text-lg h-[50px] font-semibold flex items-center justify-center">{title}</h6>
    <hr className="flex my-4 border-green-700" />
    <p>{body}</p>
  </div>
);

import { useState, useCallback, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PageLoader } from 'components/ui/PageLoader';
import { Recipe } from 'models/generated-responses';
import { ROUTE_PATHS } from 'models/routes';

import { RecipeDetails } from './RecipeDetails/RecipeDetails';

interface RecipeDetailsHandlerProps {
  getter: (id: string) => Promise<Recipe>;
  onRecipeFound?: () => void;
}

export const RecipeDetailsHandler = ({ getter, onRecipeFound }: RecipeDetailsHandlerProps) => {
  const { recipeId } = useParams<{ recipeId: string }>();
  const { t } = useTranslation();

  const [recipe, setRecipe] = useState<Recipe>();
  const [isLoading, setIsLoading] = useState(false);

  const fetchRecipe = useCallback(async () => {
    setIsLoading(true);
    try {
      if (!recipeId) return;
      const response = await getter(recipeId);
      setRecipe(response);
      onRecipeFound && onRecipeFound();
    } catch {
      toast.error(`${t('alert.unknown-error')}`);
    } finally {
      setIsLoading(false);
    }
  }, [onRecipeFound, getter, recipeId, t]);

  useLayoutEffect(() => {
    void fetchRecipe();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipeId]);

  if (isLoading) return <PageLoader />;

  return (
    <div className="lg:w-2/3 mx-auto p-5">
      {recipe ? (
        <RecipeDetails {...recipe} />
      ) : (
        <div className="py-12 flex justify-center flex-col">
          <div className="text-center font-bold text-2xl">{t('pages.recipe.no-recipe-found')}</div>
          <div className="primary-button-lg w-[fit-content] mx-auto mt-12">
            <Link to={ROUTE_PATHS.recipes}>{t('pages.recipe.back-to-recipes')}</Link>
          </div>
        </div>
      )}
    </div>
  );
};

import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Recipe } from 'models/generated-responses';
import { ROUTE_PATHS } from 'models/routes';

import './RecipeItem.scss';

export const RecipeItem: FC<Recipe> = ({ _id, name, image, status }) => (
  <Link to={`${status === 'published' ? ROUTE_PATHS.recipe : ROUTE_PATHS.pendingRecipe}/${_id}`}>
    <div className="mx-auto bg-white overflow-hidden rounded-md w-[250px] shadow-lg my-6 recipe-item">
      <div className="recipe-item__image-wrapper">
        <img src={image} alt={name} className="h-[250px] object-cover w-full text-[0px]" />
      </div>
      <div className="font-medium py-1 capitalize text-center min-h-[56px] flex flex-col justify-center">
        {name}
      </div>
    </div>
  </Link>
);

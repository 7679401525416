import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ROUTE_PATHS } from 'models/routes';

import { RecipeDetailsHandler } from '../components/recipePage/RecipeDetailsHandler';

import { useRecipesApi } from 'api/useRecipesApi';

export const PendingRecipePage = () => {
  const { getSingleRecipe, deleteRecipe, publishRecipe } = useRecipesApi();
  const { t } = useTranslation();
  const { recipeId } = useParams<{ recipeId: string }>();
  const navigate = useNavigate();

  const [isRecipeLoaded, setIsRecipeLoaded] = useState(false);

  const onDeleteRecipe = useCallback(async () => {
    try {
      if (!recipeId) return;
      await deleteRecipe({ id: recipeId });
      navigate(ROUTE_PATHS.pendingRecipes);
    } catch {
      toast.error(`${t('alert.unknown-error')}`);
    }
  }, [deleteRecipe, navigate, recipeId, t]);

  const onPublishRecipe = useCallback(async () => {
    try {
      if (!recipeId) return;
      await publishRecipe({ id: recipeId });
      navigate(ROUTE_PATHS.pendingRecipes);
    } catch {
      toast.error(`${t('alert.unknown-error')}`);
    }
  }, [navigate, publishRecipe, recipeId, t]);

  return (
    <>
      <RecipeDetailsHandler
        getter={(id: string) => getSingleRecipe({ id })}
        onRecipeFound={() => setIsRecipeLoaded(true)}
      />
      {isRecipeLoaded && (
        <div className="flex flex-col sm:flex-row gap-3 justify-center mt-16">
          <button
            className="py-2 px-5 rounded-full text-white bg-red-600"
            onClick={() => void onDeleteRecipe()}
          >
            {t('pages.pending-recipe.delete-recipe')}
            <span className="icon-delete ml-2" />
          </button>
          <button
            className="py-2 px-5 rounded-full text-white bg-green-500"
            onClick={() => void onPublishRecipe()}
          >
            {t('pages.pending-recipe.accept-recipe')}
            <span className="icon-check ml-2" />
          </button>
        </div>
      )}
    </>
  );
};

import { ChangeEvent, FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Resizer from 'react-image-file-resizer';

interface ImageInputProps {
  defaultValue?: string;
  onImageSelect: (val: string) => void;
}

export const ImageInput: FC<ImageInputProps> = ({ onImageSelect, defaultValue }) => {
  const { t } = useTranslation();

  const [resizedImage, setResizedImage] = useState<string>();

  const resizeFile = (file: File): Promise<string> =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        400,
        300,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri as string);
        },
        'base64'
      );
    });

  const handleImageSelect = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const image = e.target.files && e.target.files[0];
      if (!image) return;
      const resized = await resizeFile(image);
      setResizedImage(resized);
      onImageSelect(resized);
    },
    [onImageSelect]
  );

  return (
    <div className="mx-auto 2xl:mt-10 md:w-1/2">
      <label htmlFor="image-select">
        {resizedImage || defaultValue ? (
          <img
            src={resizedImage || defaultValue}
            alt={t('pages.send-recipe.selected-image.alt')}
            className="mx-auto object-cover rounded-xl max-w-[400px] max-h-[300px]"
          />
        ) : (
          <div className="w-[250px] h-[200px] mx-auto border-2 border-gray-300 rounded-xl text-center flex items-center bg-gray-200 justify-center flex-col cursor-pointer">
            <div className="text-2xl">+</div>
            <div className="text-sm">{t('pages.send-recipe.image.placeholder')}</div>
          </div>
        )}
      </label>
      <input
        id="image-select"
        type="file"
        accept="image/*"
        className="hidden"
        onChange={handleImageSelect}
      />
    </div>
  );
};

import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { RecipesContainer } from 'components/recipesPage/RecipesContainer';
import { AuthUser, USER_ROLES } from 'models/user';

import { useRecipesApi } from '../api/useRecipesApi';
import { Recipe } from '../models/generated-responses';

export const PendingRecipesPage = () => {
  const { user } = useAuth0<AuthUser>();
  const { getPendingRecipes } = useRecipesApi();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [recipes, setRecipes] = useState<Recipe[]>([]);

  const fetchRecipes = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getPendingRecipes();
      setRecipes(response);
    } catch {
      toast.error(`${t('alert.unknown-error')}`, { theme: 'colored' });
    } finally {
      setIsLoading(false);
    }
  }, [getPendingRecipes, t]);

  useEffect(() => {
    if (user?.user_roles.includes(USER_ROLES.admin)) void fetchRecipes();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <RecipesContainer recipes={recipes} isLoading={isLoading} />;
};

import { RecipeItem } from 'components/common/RecipeItem/RecipeItem';
import { PageLoader } from 'components/ui/PageLoader';
import { Recipe } from 'models/generated-responses';

interface RecipesProps {
  recipes: Recipe[];
  isLoading: boolean;
}
export const RecipesContainer = ({ recipes, isLoading }: RecipesProps) => (
  <>
    {isLoading ? (
      <PageLoader />
    ) : (
      <div className="flex justify-center flex-wrap p-6 md:w-2/3 lg:w-3/5 gap-6 justify-center mx-auto">
        {recipes.map((e) => (
          <RecipeItem {...e} key={e._id} />
        ))}
      </div>
    )}
  </>
);

import { useTranslation } from 'react-i18next';

import { Recipe } from 'models/generated-responses';

import { IngredientDisplay } from '../IngredientDisplay';
import { InstructionsDisplay } from '../InstructionsDisplay';

type RecipeProps = Recipe;

export const RecipeDetails = ({ name, image, ingredients, instructions, source }: RecipeProps) => {
  const { t } = useTranslation();

  return (
    <div className="lg:w-2/3 mx-auto p-5">
      <h1 className="text-3xl md:text-5xl font-thin pt-5 mb-2 mx-auto capitalize w-full text-center">
        {name}
      </h1>
      <div className="flex flex-col mt-10 sm:flex-row justify-evenly sm:justify-center mb-5">
        <div className="flex items-center sm:w-1/2">
          <img
            src={image}
            className="mx-auto max-w-full object-cover rounded-xl max-w-[400px] max-h-[300px]"
            alt={name}
          />
        </div>

        <div className="sm:flex sm:flex-col sm:justify-center sm:w-1/2">
          <IngredientDisplay ingredients={ingredients} />
        </div>
      </div>
      <InstructionsDisplay instructions={instructions} />

      {source && (
        <div className="relative mt-10">
          <span className="font-thin text-2xl mb-3 relative z-[1] bg-white pr-2">
            {t('pages.recipe.source')}
          </span>
          <div className="bg-black opacity-20 h-[1px] w-full top-4 absolute" />
          <p>{source}</p>
        </div>
      )}
    </div>
  );
};

import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Article } from 'components/mainPage/Article';
import { Card } from 'components/ui/Card';
import deforestation from 'images/deforestation.jpg';
import pigFriend from 'images/pig-friend.jpg';
import pigs from 'images/pigs.jpg';
import sushi from 'images/sushi.jpg';
import vegetables from 'images/vegetables.jpg';
import { ROUTE_PATHS } from 'models/routes';

export const AboutVeganism = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="container mx-auto overflow-hidden flex flex-col md:space-y-32 pb-12">
        <Article
          title={t('pages.main-page.article.recipes.title')}
          image={sushi}
          isReversed
          content={
            <>
              <span>{t('pages.main-page.article.recipes.content')}</span>
              <br />
              <Link
                to={ROUTE_PATHS.recipes}
                className="block primary-button-md mt-3 mx-auto w-[fit-content]"
              >
                {t('pages.main-page.article.recipes.link')}
              </Link>
            </>
          }
        />
        <h2 id="veganism" className="text-3xl text-center xl:text-5xl space font-extrabold">
          {t('pages.main-page.articles.title')}
        </h2>
        <Article
          title={t('pages.main-page.article.for-the-animals.title')}
          content={t('pages.main-page.article.for-the-animals.content')}
          image={pigs}
        />

        <Article
          title={t('pages.main-page.article.for-your-health.title')}
          image={vegetables}
          isReversed
          content={
            <Trans
              i18nKey="pages.main-page.article.for-your-health.content"
              components={{
                BDA: (
                  <Link
                    to="https://www.bda.uk.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-green-600 font-semibold hover:underline"
                  />
                ),
                AND: (
                  <Link
                    to="https://www.eatright.org/health/wellness/vegetarian-and-plant-based"
                    target="_blank"
                    rel="noreferrer"
                    className="text-green-600 font-semibold hover:underline"
                  />
                ),
              }}
            />
          }
        />
        <Article
          title={t('pages.main-page.article.for-the-environment.title')}
          content={t('pages.main-page.article.for-the-environment.content')}
          image={deforestation}
        />

        <Article
          title={t('pages.main-page.article.for-people.title')}
          content={t('pages.main-page.article.for-people.content')}
          isReversed
          image={pigFriend}
        />
      </div>
      <div className="flex flex-col md:flex-row justify-center gap-12">
        <Card
          title={t('pages.main-page.vegetarianism.title')}
          body={t('pages.main-page.vegetarianism.content')}
        />
        <Card
          title={t('pages.main-page.ethical-meat.title')}
          body={t('pages.main-page.ethical-meat.content')}
        />
      </div>
      <div className="sm:w-2/3 xl:w-1/2 mx-auto mt-8 px-12">
        <div className="text-center text-lg">
          <Trans
            i18nKey="pages.main-page.key-question"
            components={{
              highlight: <span className="font-bold text-green-700" />,
            }}
          />
        </div>
      </div>
    </div>
  );
};

import { ToastContainer } from 'react-toastify';

import { Layout } from 'components/ui/Layout';
import { Router } from 'pages/Router';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
export const App = () => (
  <>
    <Layout>
      <Router />
    </Layout>
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
  </>
);

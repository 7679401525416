import { Children } from 'react';
import { useTranslation } from 'react-i18next';

import { Recipe } from '../../models/generated-responses';

type InstructionsDisplayProps = Pick<Recipe, 'instructions'>;

export const InstructionsDisplay = ({ instructions }: InstructionsDisplayProps) => {
  const { t } = useTranslation();

  return (
    <div className="relative">
      <span className="font-thin text-2xl mb-3 mt-10 relative z-[1] bg-white pr-2">
        {t('pages.recipe.instructions')}
      </span>
      <div className="bg-black opacity-20 h-[1px] w-full top-4 absolute" />
      <ol className="text-base mt-2 list-decimal list-outside space-y-2">
        {Children.toArray(
          instructions.map((instruction) => <li className="ml-8 font-medium">{instruction}</li>)
        )}
      </ol>
    </div>
  );
};

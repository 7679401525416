import { withAuthenticationRequired } from '@auth0/auth0-react';
import { ReactNode } from 'react';

import { PageLoader } from 'components/ui/PageLoader';

interface AuthGuardProps {
  element: ReactNode;
}

export const AuthGuard = ({ element }: AuthGuardProps) => {
  const Component = withAuthenticationRequired(() => <>{element}</>, {
    onRedirecting: () => <PageLoader />,
  });

  return <Component />;
};

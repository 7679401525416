import { useAuth0 } from '@auth0/auth0-react';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthUser, USER_ROLES } from 'models/user';

export const UserDropdown = () => {
  const { user, logout } = useAuth0<AuthUser>();
  const { t } = useTranslation();

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  return (
    <div>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="items-center inline-flex w-full justify-center rounded-md text-white bg-green-500 hover:text-green-600 px-4 py-2 text-sm font-medium text-white hover:bg-green-400">
            <span className="icon-user mr-2" />
            {user?.given_name}
            <span className="icon-arrow-down ml-2" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="p-2 absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div>
              <Menu.Item>{() => <div className="font-semibold">{user?.name}</div>}</Menu.Item>
              <Menu.Item>{() => <div className="font-thin truncate">{user?.email}</div>}</Menu.Item>
              {user?.user_roles.includes(USER_ROLES.admin) && (
                <Menu.Item>{() => <div className="capitalize">{USER_ROLES.admin}</div>}</Menu.Item>
              )}
            </div>
            <div>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-green-100' : ''
                    } group flex w-full items-center rounded-md p-2 text-sm text-green-600 font-medium mt-2`}
                    onClick={logoutWithRedirect}
                  >
                    <span className="icon-logout mr-2" />
                    {t('logout')}
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

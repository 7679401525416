import { stringify } from 'qs';
import { useCallback } from 'react';

import { useApi } from 'hooks/useApi';
import { Recipe } from 'models/generated-responses';
import {
  CreateRecipeRequest,
  DeleteRecipeRequest,
  GetRecipesQuery,
  GetSingleRecipeQuery,
  PublishRecipeRequest,
} from 'models/recipes';

export const useRecipesApi = () => {
  const { sendGet, sendPost, sendDelete } = useApi();

  const getPublishedRecipes = useCallback(
    async (queryParams?: GetRecipesQuery) =>
      await sendGet<Recipe[]>(
        `/api/recipes/published${queryParams ? `?${stringify(queryParams)}` : ''}`
      ),
    [sendGet]
  );

  const getPendingRecipes = useCallback(
    async () => await sendGet<Recipe[]>('/api/recipes/pending'),
    [sendGet]
  );

  const getSingleRecipe = useCallback(
    async (queryParams: GetSingleRecipeQuery) =>
      await sendGet<Recipe>(`/api/recipes/recipe?${stringify(queryParams)}`),
    [sendGet]
  );

  const getWeeklyRecommendations = useCallback(
    async () => await sendGet<Recipe[]>('/api/recipes/weekly-recommendations'),
    [sendGet]
  );

  const createRecipe = useCallback(
    async (body: CreateRecipeRequest) =>
      await sendPost<CreateRecipeRequest, void>('/api/recipes', body),
    [sendPost]
  );

  const deleteRecipe = useCallback(
    async ({ id }: DeleteRecipeRequest) =>
      await sendDelete<DeleteRecipeRequest, void>('/api/recipes/delete-recipe', { id }),
    [sendDelete]
  );

  const publishRecipe = useCallback(
    async ({ id }: PublishRecipeRequest) =>
      await sendPost<PublishRecipeRequest, void>('/api/recipes/publish-recipe', { id }),
    [sendPost]
  );

  return {
    getPublishedRecipes,
    getSingleRecipe,
    getWeeklyRecommendations,
    createRecipe,
    getPendingRecipes,
    deleteRecipe,
    publishRecipe,
  };
};

import { Children } from 'react';
import { useTranslation } from 'react-i18next';

import { Recipe } from '../../models/generated-responses';

type IngredientDisplayProps = Pick<Recipe, 'ingredients'>;

export const IngredientDisplay = ({ ingredients }: IngredientDisplayProps) => {
  const { t } = useTranslation();

  return (
    <div className="text-2xl mt-5 relative pl-2">
      <span className="font-thin sm:mb-1 relative z-[1] bg-white pr-2">
        {t('pages.recipe.ingredients')}
      </span>
      <div className="bg-black opacity-20 h-[1px] w-full top-4 absolute" />
      <ul
        className="text-base mt-2 list-disc list-outside space-y-2 columns-2"
        style={{ columnCount: ingredients.length > 7 ? 2 : 1 }}
      >
        {Children.toArray(
          ingredients.map((ingredient) => <li className="ml-8 font-medium">{ingredient}</li>)
        )}
      </ul>
    </div>
  );
};

import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';

import hu from './translations/hu.json';

const resources = {
  hu: {
    translation: hu,
  },
};

void use(initReactI18next).init({
  resources,
  lng: 'hu',

  interpolation: {
    escapeValue: false,
  },
});

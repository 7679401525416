interface ArticleProps {
  image: string;
  isReversed?: boolean;
  title: string;
  content: string | JSX.Element;
}

export const Article = ({ image, isReversed, content, title }: ArticleProps) => (
  <div
    className={`flex flex-col md:p-5 md:mb-5 mb-8 justify-center items-center mt-0 md:gap-8 ${
      isReversed ? 'md:flex-row-reverse' : 'md:flex-row'
    }`}
  >
    <div className="flex flex-col w-full md:w-1/2 px-5 2xl:w-2/5">
      <h3 className="text-2xl xl:text-4xl font-bold text-center">{title}</h3>
      <p className="text-base my-5 text-justify">{content}</p>
    </div>
    <div className="flex justify-center items-center w-full md:w-[fit-content]">
      <img
        src={image}
        alt={title}
        className="md:rounded max-w-full md:max-w-[400px] max-h-[300px] object-contain md:shadow-2xl"
      />
    </div>
  </div>
);
